:root {
  --primary-color: #F39692;
  --secondary-color: hsl(2, 80%, 75%);
  --section-padding: 7rem;
  --body-color: #464646;
  --font-light: 100;
  --font-regular: 400;
  --font-semi: 600;
  --font-bold: 900;
}

html,
body {
  box-sizing: border-box;
  scroll-behavior: smooth;
  overflow-x: hidden;
  scroll-snap-stop: always;
  color: var(--body-color);
  font-size: 16px;
  margin: 0;
  font-family: "Urbanist", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a, a:hover, a:active {
  text-decoration: none;
  color: inherit;
}

h1{
  margin:0;
}

h2, h3, h4, h5, h6 {
  color: black;
  margin: 0;
  font-size: 2rem;
}

button {
  border: none;
  background: none;
  cursor: pointer;
}